import {
  formatItem as formatCalendarItem,
  formatCalendarItemDates,
  getLessonStatus,
} from '@/helpers/Calendar'
import { cloneDeep, merge } from 'lodash'

export default {
  /**
   * Онлайн-уроки с дополнительными данными
   * Уроки без темы не должны отображаться в ЛК ученика
   * @param {{
   *   lessons: CalendarItemI[]
   * }} state
   * @param {Object} getters
   * @param {Object} rootState
   * @param {Object} rootGetters
   * @returns {{extraData: {Object}, id: number, type: {id: number, name: string}}[]}
   */
  lessonsWithExtraData(state, getters, rootState, rootGetters) {
    const timezone = rootGetters['student/profile/timezone']

    return cloneDeep(state.lessons)
      .filter((lesson) => lesson.name !== '')
      .map((lesson) => formatCalendarItemDates(lesson, timezone))
      .map((lesson) =>
        merge(formatCalendarItem(lesson), {
          extraData: {
            status: getLessonStatus(lesson, timezone),
          },
        }),
      )
  },
}
