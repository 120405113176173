import {
  currentDateWithOffset,
  formatWithOffset,
} from '@/helpers/dateFormatHelper'
import lessonOnlineStatus from '@/helpers/lessonOnlineStatus'
import { i18n } from '@/i18n/setup'
import { add, isWithinInterval } from 'date-fns'

const getLessonDateTime = (date, time, timezone) => {
  if (!date || !time || !timezone) {
    return ''
  }

  return formatWithOffset(
    `${date}T${time}`,
    'yyyy-MM-dd HH:mm:00',
    timezone,
  ).split(' ')
}

export const useCalendarOption = () => {
  const yearBeginDate = '01-01'
  const yearEndDate = '12-31'

  const YEAR = {
    id: 'year',
    name: i18n.global.t('date_time.year'),
  }

  const MONTH = {
    id: 'month',
    name: i18n.global.t('date_time.month'),
  }

  const WEEK = {
    id: 'week',
    name: i18n.global.t('date_time.week'),
  }

  const DAY = {
    id: 'day',
    name: i18n.global.t('date_time.day'),
  }

  const dayRegExp = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/

  return {
    DAY,
    dayRegExp,
    MONTH,
    YEAR,
    yearBeginDate,
    yearEndDate,
    WEEK,
  }
}

/**
 * @type {{ONLINE_LESSON: number, GOAL: number, CTP_TEST: number}}
 */
export const types = {
  CTP_TEST: 1,
  ONLINE_LESSON: 2,
  GOAL: 3,
  CERTIFICATION: 4,
}

export const defaultCalendarTime = {
  min: '08:00',
  max: '21:00',
}

/**
 * Цвета типов событий
 * @returns {Object}
 */
export const createColorsForUser = () => ({
  [types.CTP_TEST]: '#e71b7d',
  [types.ONLINE_LESSON]: '#E6F1FB',
  [types.GOAL]: '#1f97a2',
  [types.CERTIFICATION]: '#c3c3c3',
})

/**
 * Цвета типов событий для календаря ЛК СО
 * @returns {Object}
 */
export const createColorsForStudentFe = () => ({
  [types.CTP_TEST]: '#F484B5',
  [types.ONLINE_LESSON]: '#E6F1FB',
  [types.GOAL]: '#89C3C8',
  [types.CERTIFICATION]: '#c3c3c3',
})

/**
 * Цвета типов событий
 * @returns {Object}
 */
export const createColorsForAdmin = () => ({
  [types.CTP_TEST]: '#e71b7d',
  [types.ONLINE_LESSON]: '#31b71b',
  [types.GOAL]: '#1f97a2',
})

/**
 * @returns {number[]}
 */
export const createYears = function () {
  const list = [
    new Date().getFullYear(),
  ]

  for (let i = 0; i < 2; i++) {
    list.push(list[i] - 1)
  }

  return list
}

/**
 * @param {CalendarItemI} item
 * @returns {CalendarItemI}
 */
export const formatItem = function (item) {
  let { name } = item

  if (!name) {
    name = `#${item.id}`
  }

  const extraData = item.extraData || null

  if (Number(item.type.id) === types.ONLINE_LESSON && extraData) {
    const { managers, subjects } = extraData

    const manager = managers?.[0]
    const subject = subjects?.[0]

    extraData.lessonName = name

    name = `${subject?.name} (${manager?.lastName} ${manager?.firstName} ${manager?.middleName})`
  }

  const { type } = item

  name = `${type.name}: ${name}`

  return {
    id: item.id,
    type,
    name,
    dateStart: item.dateStart,
    dateEnd: item.dateEnd,
    timeStart: item.timeStart,
    timeEnd: item.timeEnd,
    extraData,
  }
}

export const formatCalendarItemDates = (lesson, timezone) => {
  const { dateEnd, dateStart, timeEnd, timeStart } = lesson
  const [
    lessonDateEnd,
    lessonTimeEnd,
  ] = getLessonDateTime(
    dateEnd || dateStart,
    timeEnd || defaultCalendarTime.max,
    timezone,
  )

  const [
    lessonDateStart,
    lessonTimeStart,
  ] = getLessonDateTime(dateStart, timeStart, timezone)

  return {
    ...lesson,
    dateEnd: lessonDateEnd,
    dateStart: lessonDateStart,
    timeEnd: lessonTimeEnd,
    timeStart: lessonTimeStart,
  }
}

export const formatCalendarItemDatesModal = (lesson, timezone) => {
  const { lessonDate, timeEnd, timeStart } = lesson

  const [
    lessonDateStart,
    lessonTimeEnd,
  ] = getLessonDateTime(lessonDate, timeEnd, timezone)

  const [
    ,
    lessonTimeStart,
  ] = getLessonDateTime(lessonDate, timeStart, timezone)

  return {
    ...lesson,
    lessonDate: lessonDateStart,
    timeEnd: lessonTimeEnd,
    timeStart: lessonTimeStart,
  }
}

export const getLessonStatus = (lesson, timezone) => {
  const { dateEnd, dateStart, timeEnd, timeStart } = lesson

  const currentDate = currentDateWithOffset(timezone)
  const lessonStart = new Date(`${dateStart}T${timeStart}`)
  const lessonEnd = new Date(`${dateEnd}T${timeEnd}`)

  if (currentDate - lessonEnd > 0) {
    return lessonOnlineStatus.PASSED
  }

  if (currentDate - lessonStart >= 0 && currentDate - lessonEnd <= 0) {
    return lessonOnlineStatus.IN_PROGRESS
  }

  // TODO: убедиться что онлайн-урок содержит признак isSigned
  return lesson?.extraData?.isSigned
    ? lessonOnlineStatus.SIGNED
    : lessonOnlineStatus.AVAILABLE
}

export const getLessonStatusModal = (lesson, timezone) => {
  const { lessonDate, timeEnd, timeStart } = lesson

  const currentDate = currentDateWithOffset(timezone)
  const lessonStart = new Date(`${lessonDate}T${timeStart}`)
  const lessonEnd = new Date(`${lessonDate}T${timeEnd}`)

  if (currentDate < lessonStart) {
    return lessonOnlineStatus.AVAILABLE
  }

  if (currentDate >= lessonStart && currentDate <= lessonEnd) {
    return lessonOnlineStatus.IN_PROGRESS
  }

  return lessonOnlineStatus.PASSED
}

export const isLessonWithinIntervalTimeStartTimeEnd = ({
  lesson,
  timezone,
  timeStartOffset = null,
  timeEndOffset = null,
}) => {
  const { lessonDate, timeStart, timeEnd } = lesson
  const lessonStart = new Date(`${lessonDate}T${timeStart}`)
  const lessonEnd = new Date(`${lessonDate}T${timeEnd}`)

  const start = timeStartOffset
    ? add(lessonStart, timeStartOffset)
    : lessonStart
  const end = timeEndOffset ? add(lessonEnd, timeEndOffset) : lessonEnd

  return isWithinInterval(currentDateWithOffset(timezone), {
    start,
    end,
  })
}
